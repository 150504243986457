<template>
  <v-card class="mx-auto" :disabled="ldg">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <span
            class="text-h6 ml-1"
            v-text="`${$route.meta.title} ${ldg ? '' : `(${tbl_data.length})`}`"
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right"> </v-col>
        <v-col cols="12" xs="12" md="4">
          <DatePicker
            :label="'Inicial'"
            :model.sync="start_date"
            :disabled="tbl_data.length != 0"
          />
        </v-col>
        <v-col cols="12" xs="12" md="4">
          <DatePicker
            :label="'Final'"
            :model.sync="end_date"
            :disabled="tbl_data.length != 0"
          />
        </v-col>
        <v-col cols="12" xs="12" md="4">
          <v-btn
            v-if="tbl_data.length == 0"
            block
            small
            color="info"
            @click.prevent="getTblData"
            :loading="ldg"
          >
            Buscar
            <v-icon right> mdi-magnify </v-icon>
          </v-btn>
          <v-btn v-else block small @click.prevent="tbl_data = []">
            Cambiar parametros
            <v-icon right> mdi-refresh </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col v-if="tbl_data.length > 0" cols="12" xs="12" md="12">
          <v-text-field
            v-model="tbl_search"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
            dense
          />
        </v-col>
        <v-col cols="12">
          <v-data-table
            :headers="tbl_headers"
            :search="tbl_search"
            :items="tbl_data"
            :loading="ldg"
            dense
          >
            <template v-slot:item.key="{ item }">
              <b v-text="item.key + 1" />
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    fab
                    x-small
                    v-on="on"
                    class="ml-1"
                    color="primary"
                    @click.prevent="userSessionsDlg(item)"
                  >
                    <v-icon> mdi-eye-outline </v-icon>
                  </v-btn>
                </template>
                <span v-text="'Ver'" />
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog v-model="user_sessions_dlg" scrollable persistent max-width="800">
      <v-card
        tile
        :loading="user_sessions_dlg_ldg"
        :disabled="user_sessions_dlg_ldg"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> {{ user ? user.email : "" }} </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="user_sessions_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="user_sessions">
          <v-row class="pt-3">
            <v-col cols="12">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th width="20px" v-text="'#'" />
                      <th v-text="'Fecha'" />
                      <th v-text="'Inicio sesión'" />
                      <th v-text="'Cierre sesión'" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in user_sessions" :key="i">
                      <td class="font-weight-bold" v-text="i + 1" />
                      <td v-text="item.log_date" />
                      <td v-text="item.is_login ? item.created_at : ''" />
                      <td v-text="item.is_login ? '' : item.created_at" />
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import Axios from "axios";
import { URL_API, headersToken, dateTimeNow } from "../../control";
import DatePicker from "../../components/DatePicker.vue";

export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      page_route: "user_sessions",
      login: this.$store.getters.getLogin,
      start_date: "",
      end_date: "",
      ldg: false,
      tbl_data: [],
      tbl_search: "",
      tbl_headers: [
        {
          text: "#",
          filterable: true,
          value: "key",
          width: "60px",
        },
        {
          text: "Usuario",
          filterable: true,
          value: "user.email",
        },
        {
          text: "Fecha",
          filterable: true,
          value: "log_date",
        },
        {
          text: "Inicio sesión",
          filterable: true,
          value: "login.date",
        },
        {
          text: "Cerro sesión",
          filterable: true,
          value: "logout.date",
        },
        {
          text: "",
          value: "action",
          sortable: false,
          width: "60px",
        },
      ],
      user: null,
      user_sessions: null,
      user_sessions_dlg: false,
      user_sessions_dlg_ldg: false,
    };
  },
  methods: {
    getTblData() {
      this.ldg = true;

      Axios.get(
        URL_API +
          "/user/session" +
          "?start_date=" +
          this.start_date +
          "&end_date=" +
          this.end_date,
        headersToken(this.login.token)
      ).then((res) => {
        this.tbl_data = res.data.data;
        this.ldg = false;
      });
    },
    userSessionsDlg(item) {
      this.user = item.user;
      this.user_sessions = null;
      this.user_sessions_dlg_ldg = true;
      this.user_sessions_dlg = true;

      Axios.get(
        URL_API +
          "/user/session/" +
          this.user.id +
          "?log_date=" +
          item.log_date,
        headersToken(this.login.token)
      ).then((res) => {
        this.user_sessions = res.data.data;
        this.user_sessions_dlg_ldg = false;
      });
    },
  },
  mounted() {
    this.start_date = dateTimeNow().substring(0, 8) + "01";
    this.end_date = dateTimeNow().substring(0, 10);
  },
};
</script>